import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Wrapper from "../wrapper";
import { media, TransitionMixin } from "../helpers";
import { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";

const BannerContainer = styled.section`
  position: relative;
  &.half {
    .image__container {
      position: relative;
      overflow: hidden;
      max-height: 60vh;

      .overlay {
        background-color: rgb(0 0 0 / 60%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .gatsby-image-wrapper {
        min-height: 50vh;
        ${media.large`transform: translateY(-100px);`}
      }
    }
  }

  .content__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .content__inner {
      flex: 1;
    }
    h1 {
      margin: 0 0 10px;
      color: #fff;
      font-size: 32px;
      ${media.medium`font-size: 50px;`}
    }

    p {
      margin: 0 0 15px;
      color: #eee;
      font-size: 21px;
      font-weight: 500;
    }

    button {
      background-color: #d40000;
      color: #fff;
      border: 1px solid #d40000;
      padding: 12px 40px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
      ${TransitionMixin(".25s")}
      &:hover {
        background-color: #fff;
        color: #d40000;
      }
    }
  }
`;

const Banner = ({ size, image, title, caption }) => {
  let classes = [];

  if (size != null) {
    classes.push(size);
  }

  const { contactRef, distanceFromTop } = useContext(StoreContext);

  function scrollToContact() {
    window.scrollTo({
      top: contactRef.current.offsetTop - distanceFromTop,
      behavior: "smooth",
    });
  }
  return (
    <BannerContainer className={classes}>
      <div className="image__container">
        <GatsbyImage image={image} alt={title} loading="eager" />
        <div className="overlay"></div>
      </div>
      <div className="content__container">
        <Wrapper className="content__inner text-center">
          <h1>{title}</h1>
          <p>{caption}</p>
          <button onClick={() => scrollToContact()}>Contact Us</button>
        </Wrapper>
      </div>
    </BannerContainer>
  );
};

export default Banner;
