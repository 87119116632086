import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { media, TransitionMixin } from "../helpers";
import Wrapper from "../wrapper";

const AccordionsContainer = styled.section`
  padding-bottom: 70px;
  .nav-wrapper {
    display: none;
    ${media.medium`display: block;`}
    ${media.laptop`max-width: 1280px;`}
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      border: 1px solid #ccc;

      li {
        flex: 1;
        margin-bottom: 0px;

        button {
          width: 100%;
          padding: 15px 20px;
          font-size: 24px;
          background-color: transparent;
          border: none;
          ${TransitionMixin(".25s")}

          &.active,
          &:hover {
            background-color: #000;
            color: #fff;
          }
        }

        &:nth-child(2) {
          button {
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
          }
        }
      }
    }
  }

  .accordions-wrapper {
    padding-top: 30px;
    ${media.laptop`max-width: 1280px;`}

    h3 {
      color: #000;
    }

    p {
      color: #222;
      line-height: 1.6;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

const Accordions = () => {
  const accordions = [
    {
      title: "Land Use",
      description: `
        <p>Assistance with analysis of highest and best use of your property, including representation of your interests with lenders, private companies, and governmental agencies, including the City and County of San Francisco Departments of Planning, Building Inspection, Housing Inspection, Public Works and Assessor/Recorder.</p>
        <p>Counseling on issues and aspects of re-zoning or change of use.</p>
        <p>Assistance with documentation as required by the California Department of Real Estate for the purchase and sale of multi-unit properties (“Public Reports”).</p>
        `,
    },
    {
      title: "Contract Formation",
      description: `
        <p>All aspects of transactional real estate representation, including preparation of residential and commercial leases, preparation of purchase and sale of residential and commerical properties, preparation of documentation regarding sales of businesses.</p>
        <p>For developers of San Francisco County, the preparation of easement agreements, access agreements, development management agreements, co-ownership agreements for both commercial and residential properties.</p>
        <p>Creation of agreements regarding purchasing an interest in fee, a portion of an LLC, assignment and assumption of leases, purchase and sales of businesses – general agreements regarding real estate related transactions.</p>
        `,
    },
    {
      title: "Dispute Resolution",
      description: `
        <p>In conciliation, we collect factual information from both parties to a dispute, encourage open communication and facilitate discussions leading to a resolution.</p>
        <p>In mediation, we will provide an attorney to talk with the parties and guide them in working out their own mutually-agreeable solutions.</p>
        <p>If conciliation and/or mediation efforts are not successful, arbitration may be the next step. We represent clients at arbitration hearings, offer evidence and let an impartial arbitrator make a decision that ends the dispute.</p>
        `,
    },
  ];

  const [accIndex, setAccIndex] = useState(0);

  return (
    <AccordionsContainer>
      <Wrapper className="nav-wrapper">
        <ul>
          {accordions.map((acc, index) => {
            return (
              <li key={index}>
                <button
                  onClick={() => setAccIndex(index)}
                  className={accIndex === index ? "active" : ""}
                >
                  {acc.title}
                </button>
              </li>
            );
          })}
        </ul>
      </Wrapper>
      <Wrapper className="accordions-wrapper">
        {accordions.map((acc, index) => {
          return (
            <SingleAccordion
              item={acc}
              currentIndex={accIndex}
              key={`acc-${index}`}
              itemIndex={index}
            />
          );
        })}
      </Wrapper>
    </AccordionsContainer>
  );
};

export default Accordions;

const SingleAccordionContainer = styled.div`
  margin-bottom: 40px;
  ${media.medium`margin-bottom: 0px;`}
  &.hidden,
  .hidden {
    ${media.medium`display: none;`}
  }

  h3 {
    font-size: 32px;
    margin: 0 0 20px;
  }
`;

const SingleAccordion = ({ item, currentIndex, itemIndex }) => {
  return (
    <SingleAccordionContainer
      className={currentIndex !== itemIndex ? "hidden" : ""}
    >
      <h3 className="hidden">{item.title}</h3>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: item.description }}
      ></div>
    </SingleAccordionContainer>
  );
};
