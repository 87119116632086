import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { media, red } from "../helpers";
import Wrapper from "../wrapper";
import { GatsbyImage } from "gatsby-plugin-image";

const TriCTAContainer = styled.section`
  padding: 50px 0;
  ${media.medium`padding: 100px 0;`};
  .cta-wrapper {
    ${media.medium`display: flex; border-top: 2px solid #eee;`};
    ${media.laptop`max-width: 1400px;`};
  }
  .col {
    flex: 1;
    padding: 40px;
    &:nth-child(2) {
      ${media.medium`border-left: 2px solid #eee;border-right: 2px solid #eee;`};
    }
    .col__inner {
      text-align: center;
      h3 {
        font-size: 36px;
        color: #000;
        margin: 0 auto 20px;
        max-width: 320px;
        font-weight: 600;
        line-height: 1.15;
      }

      hr {
        height: 2px;
        max-width: 100px;
        margin: 0 auto 20px;
        background-color: ${red};
      }

      p {
        margin: 0 auto;
        color: #777;
        max-width: 320px;
      }
    }
  }

  .logos-wrapper {
    align-items: center;
    padding-top: 40px;
    ${media.medium`display: flex;`}
    ${media.laptop`max-width: 1400px;`};

    .image-container {
      flex: 1;
      margin-bottom: 40px;
      ${media.medium`margin-bottom: 0px;`}

      &:last-child {
        margin-bottom: 0px;
      }

      .gatsby-image-wrapper {
        max-width: 320px;
        margin: 0 auto;
      }
    }
  }
`;

const ctas = [
  {
    title: "Professional and efficient service",
    caption: "We bring over 30 years of combined experience.",
  },
  {
    title: "A team you can trust",
    caption:
      "We’ve helped over 2,000 clients between the two of us with our hands-on approach.",
  },
  {
    title: "Cost-effective and sound advice",
    caption: "Your best legal interests are our highest priority.",
  },
];

const TriCTA = () => {
  const data = useStaticQuery(graphql`
    query SiteLogosQuery {
      apt: allFile(
        filter: { relativePath: { eq: "apartment-association.png" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      bar: allFile(filter: { relativePath: { eq: "bar.png" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      spo: allFile(filter: { relativePath: { eq: "spo.jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  let logos = [data.spo, data.bar, data.apt];

  return (
    <TriCTAContainer>
      <Wrapper className="cta-wrapper">
        {ctas?.map((cta, index) => {
          return (
            <div className="col" key={`cta-${index}`}>
              <div className="col__inner">
                <h3>{cta.title}</h3>
                <hr />
                <p>{cta.caption}</p>
              </div>
            </div>
          );
        })}
      </Wrapper>

      <Wrapper className="logos-wrapper">
        {logos.map((logo, index) => {
          return (
            <div className="image-container" key={`logo-${index}`}>
              <GatsbyImage
                image={logo?.edges[0]?.node?.childImageSharp?.gatsbyImageData}
                alt=""
              />
            </div>
          );
        })}
      </Wrapper>
    </TriCTAContainer>
  );
};

export default TriCTA;
