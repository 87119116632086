import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/elements/banner";

import { useContext } from "react";
import { StoreContext } from "../context/StoreContext";
import TriCTA from "../components/elements/tri-cta-grid";
import Services from "../components/elements/services";
import Accordions from "../components/elements/accordions";
import Team from "../components/elements/team";
import Contact from "../components/elements/contact";
import Process from "../components/elements/process";

const HomePageContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const HomePage = ({ data }) => {
  const { distanceFromTop } = useContext(StoreContext);

  let topBannerData = {
    image: data?.bannerImage?.edges[0]?.node?.childImageSharp?.gatsbyImageData,
    title: "Practical real estate legal advice you can count on.",
    caption:
      "Serving the City of San Francisco as well as the Peninsula, East Bay, & North Bay.",
  };
  return (
    <Layout stickyNav={true}>
      <Seo
        title="Home"
        description={
          "Practical real estate legal advice you can count on. Serving the City of San Francisco as well as the Peninsula, East Bay, & North Bay."
        }
      />
      <HomePageContainer distanceFromTop={distanceFromTop}>
        <Banner
          title={topBannerData.title}
          caption={topBannerData.caption}
          image={topBannerData.image}
          size={"half"}
        />
        <TriCTA />
        <Services />
        <Accordions />
        <Team />
        <Process />
        <Contact />
      </HomePageContainer>
    </Layout>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />;

export default HomePage;

export const query = graphql`
  query {
    bannerImage: allFile(filter: { relativePath: { eq: "home-banner.jpg" } }) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
