import React from "react";
import styled from "styled-components";
import { media } from "../helpers";
import Wrapper from "../wrapper";

const ProcessContainer = styled.section`
  padding: 50px 0;
  ${media.medium`padding: 70px 0;`}
  .title-container {
    text-align: center;
    margin-bottom: 25px;
    h2 {
      margin: 0;
      color: #000;
      font-size: 32px;
      ${media.medium`font-size: 50px;`}
    }

    p {
      margin: 0;
      color: #777;
    }
  }

  .steps-wrapper {
    ${media.large`max-width: 1280px;`}
    .row {
      ${media.medium`display: flex;`}

      .item-container {
        display: flex;
        flex: 1;
        margin-bottom: 20px;
        ${media.medium`margin-bottom: 0px; padding: 0 20px;`}

        &:last-child {
          margin-bottom: 0;
        }

        .inner-wrap {
          background-color: #222;
          padding: 40px;
          flex: 1;
          border-radius: 10px;

          h3 {
            margin: 0 0 20px;
            color: #fff;
            font-size: 28px;
            line-height: 1.3;
          }

          p {
            color: #ccc;
            margin: 0;
            line-height: 1.6;
          }
        }
      }
    }
  }
`;

const steps = [
  {
    title: "You complete our inquiry form.",
    caption:
      "You provide a simple description of your legal matter or dispute.",
  },
  {
    title: "We run a conflicts check.",
    caption:
      "We run a quick check to make sure there aren’t any conflicts in us working together.",
  },
  {
    title: "We schedule a consultation call.",
    caption:
      "We’ll go over the more specific details of your issue so we can get started on resolving your issue.",
  },
];

const Process = () => {
  return (
    <ProcessContainer>
      <Wrapper className="title-container">
        <h2>3 easy steps to get started</h2>
      </Wrapper>

      <Wrapper className="steps-wrapper">
        <div className="row">
          {steps.map((step, index) => {
            return (
              <div className="item-container" key={`step-${index}`}>
                <div className="inner-wrap">
                  <h3>{step.title}</h3>
                  <p>{step.caption}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </ProcessContainer>
  );
};

export default Process;
