import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Wrapper from "../wrapper";
import { media, red, TransitionMixin } from "../helpers";
import { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";

const ContactContainer = styled.section`
  padding: 50px 0;
  ${media.medium`padding: 70px 0;`}

  .contact-wrapper {
    ${media.medium`display: flex;align-items: center;`}

    .image-container {
      flex: 1;
      padding: 0 20px 20px;

      ${media.medium`padding: 0 20px;`}

      p {
        font-size: 13px;
        margin: 10px 0 0;
        text-align: center;
        color: #777;
      }
    }
    .form-container {
      flex: 2;
      padding: 0 20px;

      .title-container {
        text-align: center;
        margin-bottom: 25px;
        h2 {
          font-size: 32px;
          margin: 0;
          color: #000;
          ${media.medium`font-size: 50px;`}
        }

        p {
          margin: 0;
          color: #777;
        }
      }

      form {
        max-width: 650px;
        margin: 0 auto;
        .input-row {
          margin-bottom: 20px;
          margin: 0 -20px 20px;
          ${media.medium`display: flex;`}
          .input-container {
            flex: 1;
            padding: 0 10px;
            margin-bottom: 20px;
            ${media.medium`margin-bottom: 0px;`}

            label {
              display: block;
              font-weight: bold;
              font-size: 16px;
              margin-bottom: 5px;
            }

            input {
              width: 100%;
              height: 40px;
              padding-left: 10px;
            }

            textarea {
              width: 100%;
              padding: 15px 10px;
              font-family: var(--font-sans);
            }

            p {
              color: #777;
              margin: 0px 0 10px;
              font-size: 15px;
            }

            button {
              background-color: #d40000;
              color: #fff;
              border: 1px solid #d40000;
              padding: 12px 40px;
              border-radius: 5px;
              font-size: 16px;
              font-weight: bold;
              ${TransitionMixin(".25s")}
              &:hover {
                background-color: #fff;
                color: #d40000;
              }
            }
          }
        }
      }

      .info-container {
        max-width: 650px;
        margin: 30px auto 0;

        .row {
          margin: 0 -10px;
          ${media.medium`display: flex;`}

          .address {
            flex: 1;
          }

          .contact {
            p {
              margin: 0 0 10px;
              a {
                color: ${red};
                text-decoration: none;
                ${TransitionMixin(".25s")}
                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Contact = () => {
  const [formSubmitted, setHasFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    inquiry: "",
  });
  const data = useStaticQuery(graphql`
    query SiteContactQuery {
      floodBuilding: allFile(
        filter: { relativePath: { eq: "floodbuildingsf.jpeg" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const { contactRef } = useContext(StoreContext);

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(e) {
    e.preventDefault();

    let obj = encode({
      "form-name": e.target.getAttribute("name"),
      name: formData.name,
      email: formData.email,
      inquiry: formData.inquiry,
    });

    console.log(obj);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": e.target.getAttribute("name"),
        name: formData.name,
        email: formData.email,
        inquiry: formData.inquiry,
      }),
    })
      .then(res => {
        console.log("form res", res);
        // toast("Form successfully submitted. We will reach out shortly.", {
        //   ...notificationStyles,
        // })
        setHasFormSubmitted(true);
      })
      .catch(error => alert(error));
  }

  return (
    <ContactContainer ref={contactRef}>
      <Wrapper className="contact-wrapper">
        <div className="image-container">
          <GatsbyImage
            image={
              data?.floodBuilding?.edges[0]?.node?.childImageSharp
                .gatsbyImageData
            }
            alt="Located in the historic Flood Building in San Francisco."
          />
          <p className="small">
            Located in the historic Flood Building in San Francisco.
          </p>
        </div>

        <div className="form-container">
          <Wrapper className="title-container">
            <h2>{formSubmitted ? "Thank You!" : "Contact Us"}</h2>
            <p>
              {formSubmitted
                ? "We have recieved your form submission, and will reach out shortly."
                : "Please fill out the below form and we’ll get back to you asap."}
            </p>
          </Wrapper>
          <div className="inner-wrap">
            {!formSubmitted && (
              <form
                name="contact-form-home-v2"
                method="post"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={e => handleSubmit(e)}
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="contact-form-home-v2"
                />
                <input name="bot-field" type="hidden" />
                <div className="input-row">
                  <div className="input-container">
                    <label htmlFor="full_name">Full Name</label>
                    <input
                      required
                      type="text"
                      name="full_name"
                      id="full_name"
                      value={formData.name}
                      onChange={e =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="input-container">
                    <label htmlFor="email">Email</label>
                    <input
                      required
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={e =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="input-row">
                  <div className="input-container">
                    <label htmlFor="inquiry">How can we help?</label>
                    <p>
                      In 2-3 sentences, please describe your issue. We will get
                      back to you as quickly as possible.
                    </p>
                    <textarea
                      name="inquiry"
                      id="inquiry"
                      cols="30"
                      rows="10"
                      value={formData.inquiry}
                      onChange={e =>
                        setFormData({ ...formData, inquiry: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="input-row">
                  <div className="input-container">
                    <button>Submit</button>
                  </div>
                </div>
              </form>
            )}
            <div className="info-container">
              <div className="row">
                <div className="address">
                  <p>
                    Koster & Leadbetter LLP <br />
                    The Flood Building
                    <br />
                    870 Market Street, Suite 450
                    <br />
                    San Francisco, CA 94102
                  </p>
                </div>
                <div className="contact">
                  <p>
                    <a href="tel:415.713.8680">415.713.8680</a>
                  </p>
                  <p>
                    <a href="mailto:denise@kosterleadbetterlaw.com">
                      denise@kosterleadbetterlaw.com
                    </a>
                  </p>
                  <p>
                    <a href="mailto:thomas@kosterleadbetterlaw.com">
                      thomas@kosterleadbetterlaw.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </ContactContainer>
  );
};

export default Contact;
