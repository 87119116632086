import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { media, red } from "../helpers";
import Wrapper from "../wrapper";
import { GatsbyImage } from "gatsby-plugin-image";

import AngleRight from "../../images/chevron-right.inline.svg";
import { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";

const ServicesContainer = styled.section`
  .panes-container {
    background-color: #222;
    padding: 70px 0;
    .title-container {
      text-align: center;
      margin-bottom: 25px;
      h2 {
        font-size: 32px;
        margin: 0;
        color: #fff;
        ${media.medium`font-size: 50px;`}
      }

      p {
        margin: 0;
        color: #ccc;
      }
    }

    .panes-wrapper {
      ${media.laptop`max-width: 1280px;`}
      .row {
        ${media.medium`display: flex;margin: 0 -20px;`};

        .pane {
          flex: 1;
          margin-bottom: 40px;

          ${media.medium`padding: 0 20px; margin-bottom: 0px;`};

          .image-container {
            position: relative;

            .gatsby-image-wrapper {
              min-height: 450px;
            }

            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgb(0 0 0 / 80%);
              border: 1px solid #777;
              display: flex;
              align-items: center;
              ${media.medium`display: block;`};
              .overlay-content {
                color: #fff;
                padding: 20px;

                ${media.medium`padding: 25% 50px 0;`};
                flex: 1;
                h3 {
                  font-size: 24px;
                  margin: 0 0 20px;
                  border-bottom: 2px solid red;
                  display: inline-block;

                  ${media.medium`font-size: 40px;`}
                }

                ul {
                  margin: 0 auto;
                  padding: 0;
                  list-style: none;
                  /* max-width: 320px; */

                  li {
                    display: flex;
                    align-items: start;
                    text-align: left;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5;
                    font-weight: 500;
                    ${media.medium`font-size: 18px;`}

                    &:last-child {
                      margin-bottom: 0px;
                    }

                    span {
                      position: relative;
                      top: 3px;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .services-container {
    padding: 50px 0;
    ${media.medium`padding: 100px 0;`}
    .services-wrapper {
      ${media.laptop`max-width: 1280px;`}
      .row {
        ${media.medium`display: flex;margin: 0 -20px;`};

        .service {
          flex: 1;
          margin-bottom: 40px;
          ${media.medium`padding: 0 20px; margin-bottom: 0px;`};

          &:last-child {
            margin-bottom: 0px;
          }

          .image-container {
            .gatsby-image-wrapper {
              max-width: 100px;
              margin: 0 auto;
            }
          }

          .content-container {
            text-align: center;
            h3 {
              text-align: center;
              font-size: 32px;
              color: #000;
              display: inline-block;
              border-bottom: 2px solid ${red};
              margin: 30px 0;
              ${media.medium`font-size: 40px;`}
            }

            p {
              text-align: left;
              line-height: 1.6;
              color: #222;
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
`;

const Services = () => {
  const data = useStaticQuery(graphql`
    query SiteServicesQuery {
      paneOne: allFile(filter: { relativePath: { eq: "pane-1.jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(width: 650)
            }
          }
        }
      }
      paneTwo: allFile(filter: { relativePath: { eq: "pane-2.jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(width: 650)
            }
          }
        }
      }
      home: allFile(filter: { relativePath: { eq: "home.png" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      commercial: allFile(filter: { relativePath: { eq: "building.png" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const panes = [
    {
      image: data.paneOne,
      title: "Real Estate Affairs",
      children: [
        "Property rights and land use disputes",
        "Residential and commercial landlord-tenant matters co-ownership and neighbor disputes",
        "Condo association and CC&R rules interpretation/advocacy",
        "Commercial and residential leasing and mediation services",
      ],
    },
    {
      image: data.paneTwo,
      title: "Real Estate Transactions",
      children: [
        "Purchase and sale agreements",
        "Tenancy-in common agreements",
        "Condominium conversion issues",
        "Residential & commercial landlord-tenant counseling",
        "Planning, zoning, and permitting issues",
      ],
    },
  ];

  const services = [
    {
      image: data.home,
      title: "Residential",
      description: `
      <p>Representing <strong>ONLY LANDLORDS AND PROPERTY OWNERS</strong> regarding residential property issues in San Francisco County, our lawyers bring over 30 years of combined experience in representation in landlord/tenant disputes, San Francisco Rent Board hearings, mediations, arbitrations and other dispute resolution venues.</p>
      <p>Preparation of and assistance with required notices pursuant to Section 37.9 of the San Francisco Rent Ordinance and as appropriate for prudent property management.</p>
      `,
    },
    {
      image: data.commercial,
      title: "Commercial",
      description: `
      <p>Representation of both landlords and tenants, including “small” property owners, in all aspects of lease negotiations, term extensions, lease assignments, wind-up of business lease termination agreements, exercise of option agreements, and document preparation for the refinancing of businesses (tenants) and properties (landlords).</p>
      `,
    },
  ];

  const { servicesRef } = useContext(StoreContext);

  return (
    <ServicesContainer ref={servicesRef}>
      <div className="panes-container">
        <Wrapper className="title-container">
          <h2>Services</h2>
          <p>
            We offer a wide array of residential and commercial legal services
            with an emphasis on real estate.
          </p>
        </Wrapper>

        <Wrapper className="panes-wrapper">
          <div className="row">
            {panes?.map((pane, index) => {
              return (
                <div className="pane" key={`pane-${index}`}>
                  <div className="image-container">
                    <GatsbyImage
                      image={
                        pane?.image.edges[0]?.node.childImageSharp
                          ?.gatsbyImageData
                      }
                      alt={pane.title}
                    />
                    <div className="overlay">
                      <div className="overlay-content">
                        <h3>{pane.title}</h3>
                        <ul>
                          {pane.children.map((child, index) => (
                            <li key={`child-${index}`}>
                              <span>
                                <AngleRight />
                              </span>
                              {child}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Wrapper>
      </div>

      <div className="services-container">
        <Wrapper className="services-wrapper">
          <div className="row">
            {services.map((service, index) => {
              return (
                <div className="service" key={`service-${index}`}>
                  <div className="image-container">
                    <GatsbyImage
                      image={
                        service?.image.edges[0]?.node.childImageSharp
                          ?.gatsbyImageData
                      }
                      alt={service.title}
                    />
                  </div>
                  <div className="content-container">
                    <h3>{service.title}</h3>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: service.description }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </Wrapper>
      </div>
    </ServicesContainer>
  );
};

export default Services;
