import React, { useContext } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { media } from "../helpers";
import Wrapper from "../wrapper";
import { GatsbyImage } from "gatsby-plugin-image";
import { StoreContext } from "../../context/StoreContext";

const TeamContainer = styled.section`
  background-color: #222;
  padding: 70px 0;

  .title-container {
    text-align: center;
    margin-bottom: 25px;
    h2 {
      font-size: 32px;
      margin: 0;
      color: #fff;
      ${media.medium`font-size: 50px;`}
    }

    p {
      margin: 0;
      color: #ccc;
    }
  }

  .team-wrapper {
    ${media.laptop`max-width: 950px;`}

    .row {
      ${media.medium`display: flex;`}

      .item-container {
        flex: 1;
        margin-bottom: 40px;
        ${media.medium`margin-bottom: 0px;`}

        &:last-child {
          margin-bottom: 0px;
        }

        .image-container {
          .gatsby-image-wrapper {
            max-height: 500px;

            img {
              object-position: top;
            }
          }
        }

        ${media.medium`padding: 0 20px;`}
        .content-container {
          background-color: #fff;
          padding: 40px 20px;
          ${media.medium`padding: 40px;`}
          h3 {
            margin: 0;
            font-size: 28px;
            color: #000;
            ${media.medium`font-size: 32px;`}
          }

          p {
            line-height: 1.6;
            color: #222;
            margin: 0 0 20px;
            &.partner {
              font-size: 21px;
              margin: 0 0 10px;
              color: #777;
            }
          }
        }
      }
    }
  }
`;

const Team = () => {
  const data = useStaticQuery(graphql`
    query SiteTeamQuery {
      denise: allFile(filter: { relativePath: { eq: "denise.jpeg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(width: 500, quality: 100)
            }
          }
        }
      }
      thomas: allFile(filter: { relativePath: { eq: "thomas-new.jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(width: 500, quality: 100)
            }
          }
        }
      }
    }
  `);

  const teammates = [
    {
      name: "Denise A. Leadbetter",
      bio: `
          <p>Denise graduated from Santa Clara University School of Law and is a member of the California Bar. She is admitted to appear before all courts of California, Federal District Court, and the U.S. Supreme Court.</p>
          <p>Denise was a partner with Zacks, Utrecht, and Leadbetter in San Francisco before opening the Law Office of Denise A. Leadbetter in January 2009. Then, in November 2021, she and Thomas Koster partnered up to form Koster & Leadbetter.</p>
          <p>Prior to her career as an attorney in private practice, Denise was In-House Counsel and Asset Manager for a property management company responsible for over 1 million square feet of investment property located throughout the San Francisco Bay Area.</p>
          `,

      image: data.denise,
    },
    {
      name: "Thomas Koster",
      bio: `
          <p>Thomas graduated from Santa Clara University School of Law and is a member of the California Bar. He is admitted to appear before all courts of California and California Federal District Courts. Thomas and Denise have been working together since 2018 and became partners in November 2021. Prior to working with Denise, Thomas worked as a transactional attorney in-house at a tech company and then as a real estate litigator. Thomas is the firm’s primary trial attorney, but works as a transactional attorney as well, providing unique insight into potential risks and disputes that arise out of real property transactions.</p>
          `,

      image: data.thomas,
    },
  ];

  const { teamRef } = useContext(StoreContext);
  return (
    <TeamContainer ref={teamRef}>
      <Wrapper className="title-container">
        <h2>Meet the Team</h2>
        <p>
          We bring over 30 years of combined experience in both residential and
          commercial real estate matters.
        </p>
      </Wrapper>

      <Wrapper className="team-wrapper">
        <div className="row">
          {teammates.map((item, index) => {
            return (
              <div className="item-container" key={`team-${index}`}>
                <div className="image-container">
                  <GatsbyImage
                    image={
                      item?.image.edges[0]?.node.childImageSharp
                        ?.gatsbyImageData
                    }
                    alt={item.name}
                  />
                </div>
                <div className="content-container">
                  <h3>{item.name}</h3>
                  <p className="partner">Partner</p>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.bio }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </TeamContainer>
  );
};

export default Team;
